@media screen and (min-width:1281px) {
  .font-extra-bold .MuiTypography-root {
    font-family: "Graphik-Bold";
    font-size: 3.5rem;
    line-height: 1;
    font-weight: 900;
  }

  .font-regular .MuiTypography-root {
    font-family: "Lato";
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1;
    --tw-text-opacity: 1;
    color: rgb(58 66 69 / var(--tw-text-opacity));
  }

  .header .MuiButton-root {
    font-family: "Lato";
    font-weight: 300;
  }

  .step6-email-align .MuiButton-root {
    font-family: 'Lato';
  }

  .font-regular-cards .MuiTypography-root {
    font-family: "Lato";
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
  }

  .font-medium .MuiTypography-root {
    font-family: "Graphik-Bold";
    font-weight: 600;
    font-style: normal;
    font-size: 30px;
  }

  .parent-body {

    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    padding-left: 15%;
    padding-right: 15%;
  }

  .logo {
    height: 40px;
  }

  .body-step2 {
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 100%;
    text-align: center;
    gap: 10px;
    background-image: url('../assets/images/bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: center;
  }

  .step2-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 2%;
    gap: 20px;
  }

  .body-step3 {
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    padding-left: 15%;
    padding-right: 15%;
    gap: 30px;

  }

  .dashboard {
    max-height: 729px;
  }

  .dashboard-step5 {
    max-height: 300px;
    border: 1px solid gray;
  }

  .border-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 160px;
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 6px;
    border: 2px solid #006CEC;
    max-width: 17%;
    justify-content: center;
    padding: 20px;
    height: 150px;
    background-color: #F4F9FF;
  }

  .card-thumb .MuiTypography-root {
    font-family: "Lato";
    font-weight: 800;
    font-style: normal;
    font-size: 50px;
  }

  .body-step4 {
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 100%;
    text-align: center;
    background-image: url('../assets/images/path-arrow-toleft.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: -162px;
    padding-top: 10%;
  }

  .icon-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;
    justify-content: space-between;
  }

  .icon-color .MuiSvgIcon-root {
    color: #006CEC;
    font-size: larger;
    height: 40px;
    width: 40px;
  }

  .icon-thumb {
    background: #daebff;
    padding: 20px;
    border-radius: 8px;
    width: fit-content;
  }

  .icon-thumb .MuiIcon-root {
    font-size: 2rem;
  }

  .card-step4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
    width: 80%;
    height: 26%;
  }

  .step4-cards {
    display: flex;
    flex-direction: row;
    gap: 70px;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 4%;
  }

  .step5-data {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 45%;
  }

  .step5-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .step6-grid {
    display: flex;
    flex-direction: row;
    padding-left: 15%;
    padding-right: 15%;
    justify-content: center;
  }

  .step5 {
    display: flex;
    flex-direction: column;
    gap: 50px;
    background-image: url('../assets/images/path-arrow-toright.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 9%;
    background-position-y: -14%;
  }

  .card-step6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

  }

  .card-content-step6 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .step6-align {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 200px;
    justify-content: center;
    background-color: #e9f2fd;
    padding: 2%;
  }

  .step6-icon-color .MuiSvgIcon-root {
    color: #006CEC;
  }

  .step6-email {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .step6-email-align {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
  }

  .step2-bg {
    background-image: url('../assets/images/bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: center;
  }

  .divider {
    background-image: url('../assets/images/path-arrow-toleft.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: center;
    padding-bottom: 10%;
    padding-top: 2%;
  }

  .step8-logos {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-left: 15%;
    padding-right: 15%;
    gap: 40px;

  }

  .step8-logo {
    height: 70px;
  }

  .step8-address {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 15%;
    padding-right: 15%;
  }

  .step8-address .MuiTypography-root {
    font-family: "Lato";
    font-weight: 600;
    line-height: 1;
    --tw-text-opacity: 1;
    color: rgb(58 66 69 / var(--tw-text-opacity));
  }

  .step8 {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-top: 2px solid rgba(141, 141, 141, 0.37);
  }
}

@media screen and (min-width: 769px) and (max-width: 1280px) {
  .font-extra-bold .MuiTypography-root {
    font-family: "Graphik-Bold";
    font-size: 2.3rem;
    line-height: 1;
    font-weight: 900;
  }

  .font-regular .MuiTypography-root {
    font-family: "Lato";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
    --tw-text-opacity: 1;
    color: rgb(58 66 69 / var(--tw-text-opacity));
  }

  .header .MuiButton-root {
    font-family: "Lato";
    font-weight: 300;
    font-size: 0.7rem;
  }

  .step6-email-align .MuiButton-root {
    font-family: 'Lato';
  }

  .font-regular-cards .MuiTypography-root {
    font-family: "Lato";
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
  }

  .font-medium .MuiTypography-root {
    font-family: "Graphik-Bold";
    font-weight: 600;
    font-style: normal;
    font-size: 25px;
  }

  .parent-body {

    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:10px;
  }

  .logo {
    height: 30px;
  }

  .body-step2 {
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 100%;
    text-align: center;
    gap: 10px;
    background-image: url('../assets/images/bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: center;
  }

  .step2-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2%;
    gap: 20px;
  }

  .body-step3 {
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    gap: 30px;

  }

  .dashboard {
    max-height: 550px;
  }

  .dashboard-step5 {
    max-height: 250px;
    border: 1px solid gray;
  }

  .border-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 160px;
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 6px;
    border: 2px solid #006CEC;
    max-width: 17%;
    justify-content: center;
    padding: 20px;
    height: 150px;
    background-color: #F4F9FF;
  }

  .card-thumb .MuiTypography-root {
    font-family: "Lato";
    font-weight: 800;
    font-style: normal;
    font-size: 50px;
  }

  .body-step4 {
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 100%;
    text-align: center;
    background-image: url('../assets/images/path-arrow-toleft.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: -109px;
    padding-top: 10%;
  }

  .icon-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;
    justify-content: space-between;
  }

  .icon-color .MuiSvgIcon-root {
    color: #006CEC;
    font-size: larger;
    height: 40px;
    width: 40px;
  }

  .icon-thumb {
    background: #daebff;
    padding: 20px;
    border-radius: 8px;
    width: fit-content;
  }

  .icon-thumb .MuiIcon-root {
    font-size: 2rem;
  }

  .card-step4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
    width: 80%;
    height: 26%;
  }

  .step4-cards {
    display: flex;
    flex-direction: row;
    gap: 70px;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 4%;
  }

  .step5-data {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 45%;
  }

  .step5-grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:11%;
  }

  .step6-grid {
    display: flex;
    flex-direction: row;
    padding-left: 15%;
    padding-right: 15%;
    justify-content: center;
  }

  .step5 {
    display: flex;
    flex-direction: column;
    gap: 50px;
    background-image: url('../assets/images/path-arrow-toright.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10%;
    background-position-y: -9%;
  }

  .card-step6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

  }

  .card-content-step6 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .step6-align {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 200px;
    justify-content: center;
    background-color: #e9f2fd;
    padding: 2%;
  }

  .step6-icon-color .MuiSvgIcon-root {
    color: #006CEC;
  }

  .step6-email {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .step6-email-align {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
  }

  .step2-bg {
    background-image: url('../assets/images/bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: center;
  }

  .divider {
    background-image: url('../assets/images/path-arrow-toleft.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: center;
    padding-bottom: 10%;
    padding-top: 2%;
  }

  .step8-logos {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-left: 10%;
    padding-right: 10%;
    gap: 40px;

  }

  .step8-logo {
    height: 80px;
  }

  .step8-address {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
  }

  .step8-address .MuiTypography-root {
    font-family: "Lato";
    font-weight: 600;
    line-height: 1;
    --tw-text-opacity: 1;
    color: rgb(58 66 69 / var(--tw-text-opacity));
  }

  .step8 {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-top: 2px solid rgba(141, 141, 141, 0.37);
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .font-extra-bold .MuiTypography-root {
    font-family: "Graphik-Bold";
    font-size: 2.3rem;
    line-height: 1;
    font-weight: 900;
  }

  .font-regular .MuiTypography-root {
    font-family: "Lato";
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
    --tw-text-opacity: 1;
    color: rgb(58 66 69 / var(--tw-text-opacity));
  }

  .header .MuiButton-root {
    font-family: "Lato";
    font-weight: 300;
    font-size: 0.7rem;
  }

  .step6-email-align .MuiButton-root {
    font-family: 'Lato';
  }

  .font-regular-cards .MuiTypography-root {
    font-family: "Lato";
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
  }

  .font-medium .MuiTypography-root {
    font-family: "Graphik-Bold";
    font-weight: 600;
    font-style: normal;
    font-size: 25px;
  }

  .parent-body {

    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:10px;
  }

  .logo {
    height: 30px;
  }

  .body-step2 {
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 100%;
    text-align: center;
    gap: 10px;
    background-image: url('../assets/images/bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: center;
  }

  .step2-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2%;
    gap: 20px;
  }

  .body-step3 {
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    gap: 30px;

  }

  .dashboard {
    max-height: 550px;
  }

  .dashboard-step5 {
    max-height: 250px;
    border: 1px solid gray;
  }

  .border-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 160px;
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 6px;
    border: 2px solid #006CEC;
    max-width: 17%;
    justify-content: center;
    padding: 20px;
    height: 150px;
    background-color: #F4F9FF;
  }

  .card-thumb .MuiTypography-root {
    font-family: "Lato";
    font-weight: 800;
    font-style: normal;
    font-size: 50px;
  }

  .body-step4 {
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 100%;
    text-align: center;
    background-image: url('../assets/images/path-arrow-toleft.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: -109px;
    padding-top: 10%;
  }

  .icon-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;
    justify-content: space-between;
  }

  .icon-color .MuiSvgIcon-root {
    color: #006CEC;
    font-size: larger;
    height: 40px;
    width: 40px;
  }

  .icon-thumb {
    background: #daebff;
    padding: 20px;
    border-radius: 8px;
    width: fit-content;
  }

  .icon-thumb .MuiIcon-root {
    font-size: 2rem;
  }

  .card-step4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
    width: 80%;
    height: 26%;
  }

  .step4-cards {
    display: flex;
    flex-direction: row;
    gap: 70px;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 4%;
  }

  .step5-data {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 45%;
  }

  .step5-grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:11%;
  }

  .step6-grid {
    display: flex;
    flex-direction: row;
    padding-left: 15%;
    padding-right: 15%;
    justify-content: center;
  }

  .step5 {
    display: flex;
    flex-direction: column;
    gap: 50px;
    background-image: url('../assets/images/path-arrow-toright.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10%;
    background-position-y: -9%;
  }

  .card-step6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

  }

  .card-content-step6 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .step6-align {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 200px;
    justify-content: center;
    background-color: #e9f2fd;
    padding: 2%;
  }

  .step6-icon-color .MuiSvgIcon-root {
    color: #006CEC;
  }

  .step6-email {
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .step6-email-align {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
  }

  .step2-bg {
    background-image: url('../assets/images/bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: center;
  }

  .divider {
    background-image: url('../assets/images/path-arrow-toleft.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: center;
    padding-bottom: 10%;
    padding-top: 2%;
  }

  .step8-logos {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-left: 10%;
    padding-right: 10%;
    gap: 40px;

  }

  .step8-logo {
    height: 80px;
  }

  .step8-address {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
  }

  .step8-address .MuiTypography-root {
    font-family: "Lato";
    font-weight: 600;
    line-height: 1;
    --tw-text-opacity: 1;
    color: rgb(58 66 69 / var(--tw-text-opacity));
  }

  .step8 {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-top: 2px solid rgba(141, 141, 141, 0.37);
  }
}


@media screen and (min-width: 0px) and (max-width: 599px) {
  .font-extra-bold .MuiTypography-root {
    font-family: "Graphik-Bold";
    font-size: 1rem;
    line-height: 1;
    font-weight: 900;
    text-align: justify;
  }

  .font-regular .MuiTypography-root {
    font-family: "Lato";
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 1;
    --tw-text-opacity: 1;
    color: rgb(58 66 69 / var(--tw-text-opacity));
    text-align: justify;
  }

  .header .MuiButton-root {
    font-family: "Lato";
    font-size: 0.5rem;
  }

  .step6-email-align .MuiButton-root {
    font-family: 'Lato';
    font-size: 0.7rem;

  }

  .font-regular-cards .MuiTypography-root {
    font-family: "Lato";
    font-weight: 500;
    font-style: normal;
    font-size: 10px;
  }

  .font-medium .MuiTypography-root {
    font-family: "Lato";
    font-weight: 600;
    font-style: normal;
    font-size: 30px;
    font-size: 0.7rem;
  }

  .parent-body {

    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3%;
  }


  .logo {
    height: 15px;
  }

  .body-step2 {
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 100%;
    text-align: center;
    gap: 10px;
    background-image: url('../assets/images/bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 33%;
  }

  .step2-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
  }

  .body-step3 {
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    gap: 10px;

  }

  .dashboard {
    max-height: 169px;
  }

  .dashboard-step5 {
    max-height: 90px;
    border: 1px solid gray;
  }

  .border-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 6px;
    border: 2px solid #006CEC;
    max-width: 20%;
    justify-content: center;
    padding: 10px;
    background-color: #F4F9FF;
  }

  .card-thumb .MuiTypography-root {
    font-family: "Lato";
    font-weight: 800;
    font-style: normal;
    font-size: 30px;
  }

  .body-step4 {
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 100%;
    text-align: center;
    background-image: url('../assets/images/path-arrow-toleft.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    padding-top: 24%;
  }

  .icon-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
  }

  .icon-color .MuiSvgIcon-root {
    color: #006CEC;
    font-size: larger;
    height: 40px;
    width: 40px;
  }

  .icon-thumb {
    background: #daebff;
    padding: 10px;
    border-radius: 8px;
    width: fit-content;
  }

  .icon-thumb .MuiIcon-root {
    font-size: 2rem;
  }

  .card-step4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    width: 80%;
  }

  .step4-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 4%;
  }

  .step5-data {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 45%;
  }

  .step5-grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .step6-grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .step5 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-image: url('../assets/images/path-arrow-toright.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    padding-top: 18%;
    background-position-y: -3%;
  }

  .card-step6 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  .pricing-image {
    max-width: 149px;
  }

  .card-content-step6 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .step6-align {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 48px;
    justify-content: center;
    background-color: #e9f2fd;
    padding: 2%;
  }

  .step6-icon-color .MuiSvgIcon-root {
    color: #006CEC;
  }

  .step6-email {
    gap: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .form-input-field .MuiFormControl-root-MuiTextField-root {
    width: 10%;
    font-size: 0.2rem;
  }

  .step6-email-align {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
  }

  .step2-bg {
    background-image: url('../assets/images/bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: center;
  }

  .divider {
    background-image: url('../assets/images/path-arrow-toleft.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: center;
    padding-bottom: 10%;
    padding-top: 2%;
  }

  .step8-logos {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    gap: 10px;

  }

  .step8-logo {
    height: 30px;
  }

  .step8-address {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10%;
  }

  .step8-address .MuiTypography-root {
    font-family: "Lato";
    font-weight: 400;
    line-height: 1;
    --tw-text-opacity: 1;
    color: rgb(58 66 69 / var(--tw-text-opacity));
    font-size: 0.7rem;
  }

  .step8 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-top: 2px solid rgba(141, 141, 141, 0.37);
  }

  .container {
    position: relative;
      top: 42%;
      text-align: center;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
  }

  .btn {
    text-transform: uppercase;
      font-size: 14px;
      line-height: 14px;
      text-decoration: none;
      cursor: pointer;
      display: inline-block;
      border: 1px solid #f98e03;
      color: #fff;
      padding: 9pt 1pc 8px;
      background: #f98e03;
      font-family: inherit;
      text-shadow: none;
      box-shadow: none;
      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;
      user-select: none;
      transition: all,.1s,ease-in;
      box-shadow: 0 2px 4px rgba(86,70,50,.1);
  }

}

@keyframes scaleIn {
  from {
    transform: scale(0.8);
    opacity: 0.8;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes unfold {
  0% {
    transform: perspective(1000px) rotateY(-90deg) scale(0.8);
    opacity: 0;
  }
  50% {
    transform: perspective(1000px) rotateY(15deg) scale(1.02);
    opacity: 0.8;
  }
  100% {
    transform: perspective(1000px) rotateY(0deg) scale(1);
    opacity: 1;
  }
}

@keyframes unfoldSideways {
  0% {
    transform: perspective(1000px) rotateY(-90deg) scale(0.8); /* Folded to the left */
    opacity: 0;
  }
  50% {
    transform: perspective(1000px) rotateY(15deg) scale(1.02); /* Slight overshoot */
    opacity: 0.8;
  }
  100% {
    transform: perspective(1000px) rotateY(0deg) scale(1); /* Fully unfolded */
    opacity: 1;
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #007bff; /* Blue background */
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  width: 30px; /* Fixed width */
  height: 30px; /* Fixed height */
  border-radius: 50%; /* Circle shape */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Change background color and scale on hover */
.close-button:hover {
  background-color: #ff3b30; /* Red background on hover */
  transform: scale(1.2); /* Scale effect */
}

/* Change text color on hover */
.close-button:hover span {
  color: #fff;
}

/* Close button text */
.close-button span {
  font-size: 18px; /* Adjust the icon size */
  font-weight: bold;
  color: #fff;
  transition: color 0.3s ease;
}
